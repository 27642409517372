<template>
    <header>
        <div class="event-logo">
            <img src="../assets/img/logo_white.png" alt="event-logo" />
        </div>
        <div class="event-title" style="color: white;">
            <h3>Jubatus Voting App Guide</h3>
        </div>
    </header>
    <main id="help-main">
        <section>
            <h4>Registering to vote for an event</h4>
            <p>
                To be able to register to vote, you should have received an email invite from admin@jubatus.co.ke with a link to click so as to register to your event.
                <br />You will be asked to enter your email address, password and a unique identifier for your organization e.g. member number, staff no or ID number.
            </p>
            <h4>Login to vote</h4>
            <p>
                Once you have regsitered for an event, you will have to wait until the event date to be able to vote. Once you login with the email and 
                password that you provided during registration, you will be presented with a dashboard from which you can view the candidates and vote for the during voting time.
                <br/>
                Take note that you can only vote for one candidate per voting position or post i.e. For example is three candidates are running for the position of the company
                    secretary, then only one can be voted for that position.
            </p>
            <p>Any votes cast past the voting time will not be accepted. <br/> Make sure to follow up with your organization's admin or support about
                the set voting times.
            </p>
        </section>
        <section>
            <nav>
                <router-link to="/home" class="btn btn-light">Back to Vote App</router-link>
            </nav>
        </section>
    </main>
    <footer id="footer-help">
        Jubatus Technologies &copy; 2022. All rights reserved.
    </footer>
</template>

<script>
</script>

<style scoped>
@import '../assets/css/help.css';
</style>